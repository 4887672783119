<template>
  <prism-editor
    ref="prismEditor"
    class="my-editor"
    :value="value"
    :highlight="highlighter"
    v-bind="$attrs"
    v-on="$listeners"
  ></prism-editor>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-velocity";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-php";
import "prismjs/themes/prism-dark.css"; // import syntax highlighting styles

export default {
  components: {
    PrismEditor
  },
  props: {
    idMethod: {
      type: String,
      default: "data",
      validator(value) {
        return ["query", "params", "data"].indexOf(value) !== -1;
      }
    },
    value: {
      type: String,
      default: ""
    },
    lang: {
      type: String,
      default: "php"
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.lang]); // languages.<insert language> to return html with markup
    }
  }
};
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
